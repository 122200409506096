<template>
	<edit-template class="role-edit" ref="edit" @confirm="save('form')" @cancel="open" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <!-- 大表单 -->
    <div class="form-body">
      <el-form ref="form" :model="form" :rules="rules" label-width="150rem" style="width: 500rem">
        <el-form-item label="选择校区" prop="school_id">
          <el-select :popper-append-to-body="false" v-model="form.school_id" placeholder="请选择校区" @change="changeSchool">
            <el-option v-for="item in campusArr" :label="item.school_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择楼宇" prop="building_id">
          <el-select :popper-append-to-body="false" v-model="form.building_id" placeholder="请选择楼宇" @change="changeBuilding">
            <el-option v-for="(item, index) in buildingArr" :label="item.dimicile_buliding_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择楼层" prop="storey">
          <el-select :popper-append-to-body="false" v-model="form.storey" placeholder="请选择楼层">
            <el-option v-for="item in floorArr" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="辅导地点名称" prop="addr_name">
          <el-input placeholder="请输入辅导地点名称" v-model="form.addr_name"></el-input>
        </el-form-item>

        <el-form-item label="辅导科目" prop="subject_ids">
          <el-select v-model="form.subject_ids" multiple="" placeholder="请选择辅导科目">
            <el-option v-for="item in subjectsArr" :key="item.id" :label="item.subject_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 底部按钮 -->
    
  
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    return {
      form: {storey: '', subject_ids: [], addr_name: '', building_id: ''},
      campusArr: [],
      buildingArr: [],
      floorArr: [],
      subjectsArr: [],
      rules: {
        school_id: [{required: true, message: '请选择校区', trigger: 'change'}],
        building_id: [{required: true, message: '请选择楼宇', trigger: 'change'}],
        storey: [{required: true, message: '请选择楼层', trigger: 'change'}],
        addr_name: [{required: true, message: '请输入辅导地点', trigger: 'change'}],
        subject_ids: [{required: true, message: '请选择辅导科目', trigger: 'change'}]
      },
      options: []
    }
  },

  methods: {
    save(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.$_axios2
              .post('api/1v1/place', this.form)
              .then(() => {
                this.$message({
                  type: 'success',
                  message: '添加成功!'
                })
                setTimeout(() => {
                  this.$store.commit('setPage', 1)
                  this.$router.back()
                }, 20)
              })
              .catch(() => {
              })
        }
      })
    },
    open() {
      this.$confirm('是否取消新增', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.back()
      })
    },
    changeBuilding(val) {
      this.floorArr = []
      this.form.storey = ''
      this.buildingArr.forEach((item, index) => {
        if (val == item.id) {
          for (let index = item.floor_min; index <= item.floor_max; index++) {
            this.floorArr.push(index)
          }
        }
      })
    },
    changeSchool(val) {
      this.buildingArr = []
      this.floorArr = []
      this.form.building_id = ''
      this.form.storey = ''
      this.$_axios2.get('api/1v1/place/get-building?school_id=' + val).then(res => {
        this.buildingArr = res.data.data
      })
    }
  },

  created() {
    this.$_axios.get('site/school').then(res => {
      this.campusArr = res.data.data
    })
    this.$_axios.get('site/fist-subject').then(res => {
      this.subjectsArr = res.data.data
    })
  }
}
</script>

<style lang="scss" scoped></style>
